import React from 'react';

import Link from './link';

export default ({ state, isNextStepDisabled }) => {
  return (
    <div
      className={`pt-5 d-flex flex-column flex-sm-row ${
        state.step > 1
          ? "justify-content-sm-between"
          : "justify-content-sm-center"
      } align-items-sm-center`}
    >
      {state.step > 1 && (
        <Link
          to={`/objednavka${
            state.step - 1 === 1 ? "/" : `-${state.step - 1}/`
          }`}
          state={{ ...state }}
          className="btn btn-lg btn-link"
        >
          &laquo; Zpět
        </Link>
      )}
      {state.step < 4 && (
        <Link
          to={`/objednavka-${state.step + 1}/`}
          state={{ ...state }}
          className={`btn btn-lg btn-success px-5 ${
            isNextStepDisabled ? "disabled" : ""
          }`}
        >
          Pokračovat &raquo;
        </Link>
      )}
    </div>
  )
}
