import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import IconLogo from '../icons/logo';
import Link from './link';

export class Header extends React.Component {
  state = {
    showNav: false,
  }

  toggleCategories() {
    this.setState({ showNav: !this.state.showNav })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark order-1 sticky-top">
        <div className="container">
          <Link
            className="navbar-brand"
            to="https://www.html-factory.cz/"
            aria-label="objednávka"
          >
            <IconLogo
              width="190px"
              height="54px"
              className="d-inline-block align-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarNav"
            onClick={() => this.toggleCategories()}
            aria-expanded={this.state.showNav}
            aria-label={
              this.state.showNav ? "Zavřít navigaci" : "Otevřít navigaci"
            }
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={[
              "collapse",
              "navbar-collapse",
              "justify-content-end",
              this.state.showNav ? "show" : "",
            ].join(" ")}
            id="navbarNav"
          >
            <ul className="navbar-nav align-items-lg-center py-4 py-lg-0 text-center text-sm-left">
              {this.props.data.items.map(menu => (
                <li
                  key={menu.wordpress_id}
                  className="nav-item d-flex flex-column d-sm-block"
                >
                  {menu.object_slug === "objednavka" ? (
                    <Link
                      to="/objednavka/"
                      className="nav-link px-4 btn btn-secondary text-white"
                    >
                      {menu.title}
                    </Link>
                  ) : (
                    <Link
                      to={menu.url}
                      className="nav-link px-lg-4"
                      activeClassName="active"
                    >
                      {menu.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(wordpress_id: { eq: 12 }) {
          name
          wordpress_id
          items {
            wordpress_id
            order
            title
            url
            object_slug
          }
        }
      }
    `}
    render={data => (
      <Header data={data.wordpressWpApiMenusMenusItems} {...props} />
    )}
  />
)
