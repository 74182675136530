import { Link } from 'gatsby';
import React from 'react';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
export default ({ children, to, activeClassName, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link to={to} activeClassName={activeClassName} {...other}>
        {children}
      </Link>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}
