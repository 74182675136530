import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Link from "./link"

export const Footer = ({ data }) => (
  <footer className="navbar navbar-expand-md navbar-dark bg-dark order-3 mt-5">
    <div className="container flex-column-reverse flex-md-row">
      <span className="navbar-brand">
        <small className="text-white-50">
          &copy; 2013 - {new Date().getFullYear()} html Factory
        </small>
      </span>

      <div className="navbar-collapse justify-content-end">
        <ul className="navbar-nav">
          {data.items.map(menu => (
            <li key={menu.wordpress_id} className="nav-item">
              <Link to={menu.url} className="nav-link px-4">
                {menu.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </footer>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(wordpress_id: { eq: 11 }) {
          name
          wordpress_id
          items {
            wordpress_id
            order
            title
            url
            object_slug
          }
        }
      }
    `}
    render={data => (
      <Footer data={data.wordpressWpApiMenusMenusItems} {...props} />
    )}
  />
)
