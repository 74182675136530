import React from 'react';
import Helmet from 'react-helmet';

export default ({ title = "Objednávka", description = "" }) => (
  <Helmet>
    <html lang="cs" />
    <meta content="index,follow" name="robots" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover"
    />
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content="Objednávka HTMLFACTORY" />

    <meta property="og:image" content="/social.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:type" content="image/png" />
    <meta name="twitter:image" content="/social.png" />

    <meta property="og:locale" content="cs" />
  </Helmet>
)
