import React from "react"

export default ({ width = "1.25em", height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="10 34 119 34"
    fill="currentColor"
    className={className}
  >
    <path d="M79.6 54.3v9.1l7.9 4.6 7.9-4.5v-9.1l-7.9-4.6-7.9 4.5zm12.2 4.6c0 2.4-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3zM18.4 66.8h4.1v-6.2h7.3V57h-7.3v-2.5H30V51H18.4zM36.3 51l-5.9 15.8H35l.8-2.3H42l.8 2.3h4.6l-6-15.8h-5.1zm.6 9.9l2-5.9 2 5.9h-4zM56.4 54.3c1.5 0 2.9 1 3.4 2.3l3.5-1.7c-1-2.1-3.1-4.2-6.9-4.2-4.9 0-8.6 3.3-8.6 8.2s3.7 8.2 8.6 8.2c3.8 0 5.9-2.2 6.9-4.2l-3.5-1.7c-.5 1.3-1.9 2.3-3.4 2.3-2.6 0-4.4-2-4.4-4.6 0-2.6 1.9-4.6 4.4-4.6zM64.8 54.5h4.4v12.3h4.1V54.5h4.4V51H64.8zM112.2 56.2c0-2.9-2-5.3-5.5-5.3h-7.9v15.8h4.1v-5.3h2.1l2.7 5.3h4.7l-3.3-5.7c1.4-.5 3.1-2 3.1-4.8zm-6.1 1.7h-3.2v-3.4h3.2c1.1 0 2 .6 2 1.7s-.9 1.7-2 1.7zM124.4 51l-3.4 5.8-3.5-5.8h-4.6l6 9.4v6.4h4.1v-6.4l6-9.4zM54.6 46.2H56l3.9-6.1L56 34H54.6l4 6.1zM15.1 34h-1.2L10 40.1l4 6.1h1.3l-3.9-6.1 3.9-6.1zM19.8 39.7c.5-.7 1.5-1.4 2.6-1.4 1.2 0 2 .4 2 2v5.8h1.4v-6.2c0-1.9-1-2.8-2.8-2.8-1.3 0-2.5.7-3.1 1.5V34h-1.5v12.2h1.4v-6.5zM28.9 44.3c0 1.3.7 2.1 2 2.1.8 0 1.3-.2 1.6-.6l-.4-1c-.2.2-.5.4-.9.4-.6 0-.9-.5-.9-1.1v-5.5h1.8v-1.2h-1.8v-2.5h-1.4v2.4h-1.5v1.2h1.5v5.8zM36.2 39.7c.5-.7 1.4-1.4 2.3-1.4 1.2 0 1.6.7 1.6 1.8v6h1.4v-6.4c.5-.7 1.5-1.3 2.4-1.3 1.1 0 1.6.7 1.6 1.8v6h1.4v-6.4c0-1.8-.9-2.7-2.5-2.7-1.3 0-2.5.8-3 1.6-.3-.9-1-1.6-2.4-1.6-1.3 0-2.5.9-2.9 1.5v-1.2h-1.4v8.8h1.4l.1-6.5zM50.1 34h1.4v12.2h-1.4z" />
  </svg>
)
