import React from 'react';

import Link from './link';

const steps = ["Služba", "Administrace", "Cena", "Objednávka"]

export default ({ state }) => (
  <div className="list-group list-group-horizontal">
    {steps.map((item, key) => {
      const currentValue = {
        0: state.service && state.service.name,
        1: state.subService && state.subService.name,
        2: state.priceSummary && state.priceSummary.priceAfterModified + " Kč",
      }

      return (
        <Link
          to={`/objednavka${key === 0 ? "/" : `-${key + 1}/`}`}
          state={{ ...state }}
          key={key}
          className={`btn btn-lg btn-light list-group-item flex-grow-1 border-light rounded-0 flex-column ${
            state.step === key + 1 ? "bg-light" : ""
          } ${state.step < key + 1 ? "disabled" : ""}`}
        >
          <p className="mb-0 d-flex">
            {key + 1}.{" "}
            <span className="d-none d-md-block ml-1 ml-xl-2">{item}</span>
          </p>
          {state.step > key + 1 && currentValue[key] && (
            <small className="mb-0 d-none d-md-flex">{currentValue[key]}</small>
          )}
        </Link>
      )
    })}
  </div>
)
