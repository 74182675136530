import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Nav from './orderFooterNav';
import Stepper from './orderStepper';

export default ({ children, state, isNextStepDisabled }) => (
  <Layout>
    <Seo
      title="Objednávka webových služeb | HTMLFACTORY"
      description="Nezávazně spočítejte cenu vašeho projektu, jednoduše, pomoci pár kliknutí."
    />

    <div className="text-center py-5 bg-light">
      <div className="container">
        <h1>Objednávka webových služeb</h1>
        <p>
          Nezávazně spočítejte cenu vašeho projektu, jednoduše, pomoci pár
          kliknutí.
        </p>
      </div>
    </div>

    <div className="py-4 py-md-5">
      <div className="container">
        <Stepper
          state={state}
          handleChangeState={value => this.handleChangeState(value)}
        />

        <div
          className="mx-n3 mx-sm-0 py-4 px-3 p-md-4 p-lg-5 bg-light"
          style={{ minHeight: "630px" }}
        >
          {children}

          <Nav state={state} isNextStepDisabled={isNextStepDisabled} />
        </div>
      </div>
    </div>
  </Layout>
)
