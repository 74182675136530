import React from "react"
import Header from "./header"
import Footer from "./footer"

import "../sass/global.scss"

export default ({ children }) => (
  <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
    <main className="flex-grow-1 order-2">{children}</main>
    <Header />
    <Footer />
  </div>
)
